import { RouteLocationNormalized } from 'vue-router';
import AuthService from './auth.service';

class DirectManagersService {

    private userEmployees: number[] = [];

    constructor() {
        AuthService.user$.subscribe((user) => {
            //@ts-ignore
            const { userEmployeesIds = [] } = user || {}; 
            this.userEmployees = userEmployeesIds;
        });
    }

    userCan(to: RouteLocationNormalized): boolean {
        return (this.userEmployees || []).includes(+to.params.id);
    }
}

export default new DirectManagersService();
