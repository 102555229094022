import { Directive } from 'vue';
import PermissionService from '@/services/permission.service';

const UserCanDirective = function (): Directive<HTMLElement, string | string[]> {
    return {
        mounted(el, binding) {
            el.hidden = !PermissionService.userCan(Array.isArray(binding.value) ? binding.value : [binding.value]);
        }
    }
}

export default UserCanDirective();
