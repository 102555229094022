import { TLazyModalComponent, TModalDataMap } from '@/types/modals/modal-data-map';
import { shallowRef, ShallowRef } from 'vue';
import { filter, Observable, Subject, take } from 'rxjs';
import { ModalResultEnum } from '@/types/modals/modal-result.enum';

export type TShowModalResult<M extends keyof TModalDataMap> = {
    afterClosed(filterResult?: ModalResultEnum): Observable<TModalDataMap[M]['result']>;
    readonly _instance: ModalConfigInstance<M>;
}

export type TModalConfig = {
    closeBtnTitle?: string;
    header?: string;
    onCloseCustom?: () => void;
}

export class ModalConfigInstance<M extends keyof TModalDataMap = any> {
    
    active = false;
    public readonly Component: ShallowRef = shallowRef();
    public readonly config: TModalConfig;
    
    private _afterClosed: Subject<TModalDataMap[M]['result']> = new Subject<TModalDataMap[M]['result']>();
    
    constructor(
        public readonly modalName: M,
        public readonly data: TModalDataMap[M]['data'],
        lazyComponent: TLazyModalComponent,
        config?: TModalConfig,
    ) {
        this.config = config || {};
        this.loadComponent(lazyComponent);
    }
    
    close(modalResult: ModalResultEnum = ModalResultEnum.Closed, data?: TModalDataMap[M]['result']['data']) {
        this.active = false;
        this._afterClosed.next({ modalResult, data });
    }
    
    show(): TShowModalResult<M> {
        this.active = true;
        return {
            afterClosed: (filterResult?: ModalResultEnum) => this._afterClosed.pipe(
                take(1),
                filter(({ modalResult }) => {
                    return modalResult === (filterResult ?? modalResult);
                })
            ),
            _instance: this
        }
    }
    
    private loadComponent(lazyComponent: TLazyModalComponent) {
        lazyComponent.subscribe({
            next: ({ Component }) => {
                this.Component.value = Component;
            },
            error: (error) => {
                console.error(error);
                this.close();
            }
        });
    }
}
