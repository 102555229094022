import { TModalDataMap } from '@/types/modals';

/*
* map modalNames to Components
* */
type TComponent = any;
export const ModalComponentMap: {
    [K in keyof TModalDataMap]: () => Promise<{ default: TComponent }>;
} = {
    fieldEditContentBeforeAfter: () => import('@/components/modals/forms/FormContentBeforeAfterEditModal.vue'),
    conditionsWarning: () => import('@/components/modals/forms/FormFieldConditionsWarnModal.vue'),
    editConditions: () => import('@/components/modals/forms/FormConditionsEditModal.vue'),
    groupCloneModal: () => import('@/components/modals/groups/GroupCloneModal.vue'),
    groupCreateModal: () => import('@/components/modals/groups/GroupCreateOrUpdateModal.vue'),
    meetingRoomCreateModal: () => import('@/components/modals/meetings/MeetingRoomCreateModal.vue'),
    meetingShowModal: () => import('@/components/modals/meetings/MeetingShowModal.vue'),
    meetingRoomEditModal: () => import('@/components/modals/meetings/MeetingRoomEditModal.vue'),
    meetingCreateModal: () => import('@/components/modals/meetings/MeetingCreateModal.vue'),
    userEditModal: () => import('@/components/modals/users/UserEditModal.vue'),
    categoryCreateModal: () => import('@/components/modals/categories/CategoryCreateModal.vue'),
    categoryEditModal: () => import('@/components/modals/categories/CategoryEditModal.vue'),
    editDoorModal: () => import('@/components/modals/skd/EditDoorModal.vue'),
    editOfficeModal: () => import('@/components/modals/skd/EditOfficeModal.vue'),
    userLinkToSkdModal: () => import('@/components/modals/users/UserLinkToSkdModal.vue'),
        candidateLinkEmployeeModal: () => import('@/components/modals/recruiting/CandidateLinkEmployeeModal.vue'),
        timeToFillEditModal: () => import('@/components/modals/recruiting/TimeToFill/TimeToFillEditModal.vue'),
        timeToFillCreateModal: () => import('@/components/modals/recruiting/TimeToFill/TimeToFillCreateModal.vue'),
    hiredValueEditModal: () => import('@/components/modals/recruiting/HiredValueEditModal.vue'),
    hiredValueCreateModal: () => import('@/components/modals/recruiting/HiredValueCreateModal.vue'),
    formFieldEdit: () => import('@/components/modals/forms/FormFieldEdit.modal.vue'),
} as const;
