import { createApp } from 'vue';
import App from './App.vue'
import { router } from './router';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import UserCanDirective from '@/directives/userCan.directive';
import { FontAwesomeIcon } from './core/plugins/font-awesome';
import Tooltip from 'primevue/tooltip';                       // tooltips
import Sidebar from 'primevue/sidebar';                       // sidebar
import Toast from "primevue/toast";                           // toasts
import Button from "primevue/button";
import PanelMenu from 'primevue/panelmenu';
import InputSwitch from "primevue/inputswitch";
import StyleClass from "primevue/styleclass";
import Ripple from "primevue/ripple";
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import ConfirmationService from 'primevue/confirmationservice';
import VueApexCharts from "vue3-apexcharts";

// Static
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";            // bootstrap icons
import 'primevue/resources/themes/saga-blue/theme.css';       // theme
import 'primevue/resources/primevue.css'
import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';                           // icons
import './assets/main.scss';

createApp(App)
    .use(router)
    .use(PrimeVue)
    .use(ToastService)
    .use(ConfirmationService)
    // .use(VueApexCharts)
    .component('VueApexCharts', VueApexCharts)
    .component('Sidebar', Sidebar)
    .component('Button', Button)
    .component('InputSwitch', InputSwitch)
    .component('InputText', InputText)
    .component('Password', Password)
    .component('Checkbox', Checkbox)
    .component('fa', FontAwesomeIcon)
    .component("Toast", Toast)
    .component("PanelMenu", PanelMenu)
    .component("Dialog", Dialog)
    .directive('tooltip', Tooltip)
    .directive('styleClass', StyleClass)
    .directive('ripple', Ripple)
    .directive('userCan', UserCanDirective)
    .mount('#app');
