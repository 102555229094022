import { ref } from 'vue';
import {
    ModalConfigInstance,
    TModalConfig,
    TShowModalResult
} from '@/types/modals/modal-config-instance';
import {
    TLazyModalComponent,
    TModalDataMap
} from '@/types/modals';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ModalComponentMap } from '@/constants/modals';
import { map } from 'rxjs/operators';


class ModalsService {
    
    readonly modalsList = ref<ModalConfigInstance[]>([]);
    
    show<M extends keyof TModalDataMap>(
        modalName: M,
        data: TModalDataMap[M]['data'],
        config?: TModalConfig
    ): TShowModalResult<M> {
        const lazyModalComponent = this.getModalComponent(modalName);
        const showModalResult = new ModalConfigInstance(modalName, data, lazyModalComponent, config).show();
        this.modalsList.value.push(showModalResult._instance);
        // remove from array after closed
        showModalResult.afterClosed().subscribe(() => this.removeModal(showModalResult._instance));
        // result:
        return showModalResult;
    }
    
    private getModalComponent(name: keyof TModalDataMap): TLazyModalComponent {
        return fromPromise(ModalComponentMap[name]()).pipe(
            map((module) => ({ Component: module.default }))
        );
    }
    
    private removeModal(modal: ModalConfigInstance) {
        setTimeout(() => {
            const index = this.modalsList.value.findIndex((item) => item === modal);
            this.modalsList.value.splice(index, 1);
        }, 300);
    }
    
}


export default new ModalsService();
