import AuthService from './auth.service';
import { RouteLocationNormalized } from 'vue-router';
import { GroupsApi, PermissionsApi, UsersApi } from '@/types/api';
import ApiService from '@/services/api.service';

class PermissionService extends ApiService {

    private permissions: UsersApi.User.Get['permissions'] = [];
    private readonly endpoints = {
        addGroupPermission: `permissions/groups/add`,
        addDirectPermission: `permissions/users/add`,
        removeDirectPermission: `permissions/users/remove`,
        removeGroupPermission: `permissions/groups/remove`,
        searchByName: `permissions/search`,
    };

    constructor() {
        super();
        AuthService.user$.subscribe((user) => {
            const { permissions = [] } = user || {};
            this.permissions = permissions;
        });
    }

    collectPermissionFromRouter(route: RouteLocationNormalized) {
        return (route?.matched || []).reduce<string[]>((result, item) => {
            const { permissions } = item.meta;
            return [...result, ...(permissions || [])];
        }, []);
    }

    userCan(checkPermissions: string[]) {
        return checkPermissions.every((p) => (this.permissions || []).includes(p));
    }

    addGroupPermission(groupId: GroupsApi.Get['id'], permissionId: PermissionsApi.Get['id']) {
        return this.post<{ permission_id: PermissionsApi.Get['id'] }, {}>([this.endpoints.addGroupPermission, groupId].join('/'), {
            permission_id: permissionId
        });
    }

    addDirectPermission(userId: GroupsApi.Get['id'], permissionId: PermissionsApi.Get['id']) {
        return this.post<{ permission_id: PermissionsApi.Get['id'] }, {}>([this.endpoints.addDirectPermission, userId].join('/'), {
            permission_id: permissionId
        });
    }

    removeDirectPermission(userId: GroupsApi.Get['id'], permissionId: PermissionsApi.Get['id']) {
        return this.post<{ permission_id: PermissionsApi.Get['id'] }, {}>([this.endpoints.removeDirectPermission, userId].join('/'), {
            permission_id: permissionId
        });
    }

    removeGroupPermission(groupId: GroupsApi.Get['id'], permissionId: PermissionsApi.Get['id']) {
        return this.post<{ permission_id: PermissionsApi.Get['id'] }, {}>([this.endpoints.removeGroupPermission, groupId].join('/'), {
            permission_id: permissionId
        });
    }

    search(query: string) {
        return this.get<PermissionsApi.Get[]>(
            this.endpoints.searchByName,
            {
                params: {
                    query
                }
            }
        );
    }

}

export default new PermissionService();
